import { createRouter, createWebHistory } from 'vue-router'

import MainLayout from '@/Layouts/MainLayout.vue'

function lazyLoad(view){
  return() => import(`@/views/${view}/${view}.vue`)
}

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: lazyLoad('Home'),
        meta: { scrollToTop: true }
      },
      {
        path: 'about-us',
        name: 'about',
        component: lazyLoad('AboutUs'),
        meta: { scrollToTop: true }
      },
      {
        path: 'contact',
        name: 'contact',
        component: lazyLoad('Contact'),
        meta: { scrollToTop: true }
      },
      {
        path: 'pricing',
        name: 'pricing',
        component: lazyLoad('Prices'),
        meta: { scrollToTop: true }
      },
      {
        path: 'services',
        name: 'services',
        component: lazyLoad('Services'),
        meta: { scrollToTop: true }
      },
      {
        path: 'find-us',
        name: 'find-us',
        component: lazyLoad('Find-us'),
        meta: { scrollToTop: true }
      },
      {
        path: 'training',
        name: 'training',
        component: lazyLoad('Training'),
        meta: { scrollToTop: true }
      },
      {
        path: 'products',
        name: 'products',
        component: lazyLoad('Products'),
        meta: { scrollToTop: true }
      },
      {
        path: 'certification',
        name: 'certification',
        component: lazyLoad('Certification'),
        meta: { scrollToTop: true }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    //document.getElementById('app').scrollIntoView({ behavior: 'auto' });
    if (to.hash) {
      return {
        el: to.hash,
        top: 150,
        y: 0
      }
    } else {
      return savedPosition || { top: 0, behavior: 'auto' }
    }
  }
})

export default router
