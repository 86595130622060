<template>
  <div class="icons">
    <a :href="webInfo?.facebook_url?.value" target="_blank" title="Facebook">
      <FacebookSvg class="icon"/>
    </a>
    <a :href="webInfo?.instagram_url?.value" target="_blank" title="Instagram">
      <InstagramSvg class="icon"/>
    </a>
    <a :href="webInfo?.linkedin_url?.value" target="_blank" title="Linkedin">
      <LinkedinSvg class="icon" />
    </a>
    <!-- <a href="https://youtube.com/channel/UC2Y1jW07y68lzpM-VN0emUA" target="_blank" title="Youtube">
      <YoutubeSvg class="icon" />
    </a> -->
  </div>
</template>

<script>
import FacebookSvg from './Icons/FacebookSvg.vue'
import InstagramSvg from './Icons/InstagramSvg.vue'
import LinkedinSvg from './Icons/LinkedinSvg.vue'
// import YoutubeSvg from './Icons/YoutubeSvg.vue'
import { useAppStore } from '@/stores/app';
import { computed } from 'vue';

export default {
  components: {
    FacebookSvg,
    InstagramSvg,
    LinkedinSvg,
    // YoutubeSvg,
  },
  setup() {
    const app = useAppStore();

    const webInfo = computed(() => app.webInfo);

    return {
      webInfo,
    };
  },
}
</script>

<style scoped>
.icons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icons a {
  color: var(--white);
  height: 25px;
}

.icon {
  width: 22px;
  height: 22px;
}
</style>
