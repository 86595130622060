<template>
  <img loading="lazy" width="100" height="80" class="mb-2 logo" src="@/assets/icons/Logotipo-dorado.svg"
  :alt="$t('alt.logo')">
  <div class="icons-social-media">
    <a :href="webInfo?.facebook_url?.value" target="_blank" title="Facebook">
      <FacebookSvg class="icon"/>
    </a>
    <a :href="webInfo?.instagram_url?.value" target="_blank" title="Instagram">
      <InstagramSvg class="icon"/>
    </a>
    <a :href="webInfo?.linkedin_url?.value" target="_blank" title="Linkedin">
      <LinkedinSvg class="icon" />
    </a>
    <!-- <a :href="webInfo?.youtube_url?.value" target="_blank" title="Youtube">
      <YoutubeSvg class="icon" />
    </a> -->
    <img loading="lazy" class="icon" src="@/assets/icons/iso.svg"
    :alt="$t('alt.footer.iso')">
  </div>
</template>

<script>
import FacebookSvg from '../Icons/FacebookSvg.vue'
import InstagramSvg from '../Icons/InstagramSvg.vue'
import LinkedinSvg from '../Icons/LinkedinSvg.vue'
// import YoutubeSvg from '../Icons/YoutubeSvg.vue'

export default {
  props: {
    webInfo: {
      type: Object,
      default: null,
    },
  },
  components: {
    FacebookSvg,
    InstagramSvg,
    LinkedinSvg,
    // YoutubeSvg
  }
}
</script>

<style scoped>
.logo {
	width: 80%;
}
.icons-social-media {
	margin-left: 10px;
}
.icons-social-media a {
  color: #ad841c;
  margin: 5px;
}
.icons-social-media .icon {
  width: 30px;
  height: 30px;
}
</style>
