<template>
  <div class="modal-fullscreen">
    <dic class="container-spinner">
      <img loading="lazy" src="@/assets/icons/logo-gold-circle.svg" alt="">
      <Spinner size="120px"/>
    </dic>
  </div>
</template>

<script>
import Spinner from './Spinner.vue'

export default {
  components: {
    Spinner
  }
}
</script>

<style scoped>
.modal-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2em;
}

.container-spinner {
  position: relative
}

.container-spinner {
  display: grid;
  place-items: center
}

.container-spinner img {
  position: absolute;
  width: 90px;
}
</style>
