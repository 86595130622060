<template>
  <div v-if="!showLoading">
    <Header />
    <Banner :key="routeName" />
    <router-view></router-view>
    <Footer />
    <ButtonFab />
  </div>

  <LoadingInitial v-if="showLoading" />
</template>

<script>
import Header from "../components/Header.vue";
import Footer from '../components/Footer/Footer.vue'
import ButtonFab from '../components/ButtonFab.vue'
import Banner from '../components/Banner.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import LoadingInitial from '../components/LoadingInitial.vue'
import { useAppStore } from '../stores/app';

export default {
  components: {
    LoadingInitial,
    Banner,
    Footer,
    ButtonFab,
    Header
  },
  setup() {
    const route = useRoute()
    const app = useAppStore()

    const routeName = computed(() => route.name)
    const showLoading = computed(() => app.loadingInit)

    return {
      routeName,
      showLoading
    }
  }
}
</script>
