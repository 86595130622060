import axios from './http'
import languagesByHost from '../helpers/languagesByHost'

export default function setup() {
  //Interceptor request
  axios.interceptors.request.use(
    function(config) {
      const language = languagesByHost[window.location.hostname] || 'en'
      if (language) {
        config.headers.RefDomain = language
      }
      return config
    },
    function(err) {
      return Promise.reject(err)
    }
  )

  //Interceptor response
  // axios.interceptors.response.use(
  //   function(response) {
  //     // Do something with response data
  //     return response
  //   },
  //   function(error) {
  //     if (error.response.status === 401) {
  //       localStorage.removeItem('token')
  //       window.location = 'login'
  //     }
  //     if (error.response.status === 402) {
  //       console.log(error.response)
  //     }
  //   }
  // )
}
