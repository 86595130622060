<template>
  <router-view></router-view>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useAppStore } from './stores/app';
import languagesByHost from './helpers/languagesByHost'
import AOS from "aos";

export default {
  setup() {
    const { setLanguage, setLoadingInit, fetchGlobalData } = useAppStore();
    const showSpinner = ref(false);

    const init = async () => {
      await fetchGlobalData();
      setTimeout(() => {
        setLoadingInit(false);
      }, 1000);
    }

    onMounted(() => {
      init();

      const language = languagesByHost[window.location.hostname] || 'en'
      setLanguage(language)
      AOS.init({
        duration: 900,
        disable: 'mobile'
      });
    });

    return {
      showSpinner
    }
  },
}
</script>
