<template>
  <p class="footer-title-section font-montserrat-sb m-0">{{ $t('footer.links')}}</p>
  <ul class="footer-nav">
    <li>
      <router-link to="/" :title="$t('footer.links-home')">{{ $t('footer.links-home')}}</router-link>
    </li>
    <li>
      <router-link to="/about-us" :title="$t('footer.links-company')">{{ $t('footer.links-company')}}</router-link>
    </li>
    <li>
      <router-link to="/services" :title="$t('footer.links-services')">{{ $t('footer.links-services')}}</router-link>
    </li>
    <li>
      <router-link to="/contact" :title="$t('footer.links-contact')">{{ $t('footer.links-contact')}}</router-link>
    </li>
    <li>
      <router-link to="/certification" :title="$t('navbar.certification')">{{ $t('navbar.certification')}}</router-link>
    </li>
  </ul>
</template>

<style scoped>
ul {
  padding: 0 !important;
  gap: 3em;
}

ul li {
  list-style: none;
  font-size: 18px;
}

.footer-nav a {
  position: relative;
  text-decoration: none;
  color: #000000;
}

.footer-nav a.router-link-exact-active {
  border-bottom: 2px solid var(--primary-color)
}

.footer-nav a:not(.router-link-exact-active)::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000000;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.footer-nav a:hover::after,
.footer-nav a:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}
</style>
