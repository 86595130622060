<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid px-3">

      <SocialMediaIcons class="me-3" />

      <router-link class="navbar-brand m-0" to="/" exact title="CloverLeaf AWS">
        <img loading="lazy" width="150" height="45" src="@/assets/icons/Logotipo-dorado.svg" alt="">
      </router-link>

      <button @click="openMobileMenu()" class="navbar-toggler" aria-label="Toggle navigation">
        <MenuIcon />
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-lg-0">
          <li class="nav-item">
            <router-link to="/" exact :title="$t('navbar.home')">{{ $t('navbar.home') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about-us" exact :title="$t('navbar.about')">{{ $t('navbar.about') }}</router-link>
          </li>
          <li class="nav-item d-block d-sm-block d-md-block d-lg-none">
            <router-link to="/services" :title="$t('navbar.services')">{{ $t('navbar.services') }}</router-link>
          </li>
          <li class="nav-item d-block d-sm-block d-md-block d-lg-none">
            <router-link to="/pricing" :title="$t('navbar.pricing')">{{ $t('navbar.pricing') }}</router-link>
          </li>
          <li class="nav-item d-block d-sm-block d-md-block d-lg-none">
            <router-link to="/find-us" :title="$t('navbar.find-us')">{{ $t('navbar.find-us') }}</router-link>
          </li>
          <li class="nav-item d-block d-sm-block d-md-block d-lg-none">
            <router-link to="/training" :title="$t('navbar.training')">{{ $t('navbar.training') }}</router-link>
          </li>
          <li class="nav-item d-block d-sm-block d-md-block d-lg-none">
            <router-link to="/contact" :title="$t('navbar.contact')">{{ $t('navbar.contact') }}</router-link>
          </li>
          <li class="nav-item d-block d-sm-block d-md-block d-lg-none">
            <router-link to="/products" :title="$t('navbar.products')">{{ $t('navbar.products') }}</router-link>
          </li>

          <li class="nav-item position-relative d-none d-sm-none d-md-none d-lg-block">
            <Submenu :open="openSubmenu === 'services'" name-menu="navbar.services" href="/services" :items-menu="[
              { name: 'navbar.pricing', path: '/pricing' },
              { name: 'navbar.training', path: '/training' },
              { name: 'navbar.products', path: '/products' }
            ]" @onOpen="handleOpenSubmenu" />
          </li>
          <li class="nav-item position-relative d-none d-sm-none d-md-none d-lg-block">
            <Submenu :open="openSubmenu === 'contact'" name-menu="navbar.contact" href="/contact" :items-menu="[
              { name: 'navbar.find-us', path: '/find-us' },
            ]" @onOpen="handleOpenSubmenu" />
          </li>

          <li class="nav-item">
            <router-link to="/certification" exact :title="$t('navbar.certification')">{{ $t('navbar.certification')
            }}</router-link>
          </li>
        </ul>
      </div>

      <ButtonHover class="d-none d-sm-none d-md-none d-lg-block me-3" backgroundColor="#9a7a2a"
        :btn-text="$t('button-get-certified')" @click="openForm()" />

      <UserActions />
    </div>
  </nav>
</template>

<script>
import ButtonHover from './ButtonHover.vue'
import MenuIcon from './Icons/MenuSvg.vue'
import Submenu from './Submenu.vue'
import { useRoute, useRouter } from 'vue-router'
import { computed, watch, ref } from 'vue'
import UserActions from './UserActions.vue'
import SocialMediaIcons from './SocialMediaIcons.vue'

export default {
  components: {
    UserActions,
    ButtonHover,
    MenuIcon,
    Submenu,
    SocialMediaIcons
  },
  setup() {
    const router = useRouter()
    const route = useRoute();
    const routeName = computed(() => route.name)
    const openSubmenu = ref(null)

    /*Create watch to change route and close mobile menu*/
    watch(routeName, () => {
      const navbar = document.getElementById('navbarSupportedContent')
      navbar.classList.remove('show')
    })


    const openForm = () => {
      if (routeName.value !== 'contact') {
        router.push({
          name: 'contact',
          hash: '#form-contact'
        })
      }
    }

    const handleOpenSubmenu = (name) => openSubmenu.value = name

    const openMobileMenu = () => {
      const navbar = document.getElementById('navbarSupportedContent')
      navbar.classList.toggle('show')
    }

    return {
      openSubmenu,
      routeName,
      handleOpenSubmenu,
      openForm,
      openMobileMenu
    }
  }
}
</script>

<style>
.navbar {
  background-color: var(--primary-color) !important;
  font-family: var(--montserrat-semibold);
  width: 100%;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-brand img {
  width: 9em;
}

.navbar-nav a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  position: relative;
}

.navbar-nav a {
  color: white !important;
}

nav li a.router-link-exact-active {
  border-bottom: 0.1em solid white
}

.navbar-nav a:not(.router-link-exact-active)::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: white;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.navbar-nav a:hover::after,
.navbar-nav a:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}

.navbar-nav {
  gap: 3em;
  margin-right: auto !important;
  margin-left: auto !important;
}

.navbar-nav .nav-item a {
  font-size: 1em;
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .navbar-brand img {
    width: 8em;
  }

  .navbar-nav {
    gap: 2em;
  }

  .navbar-nav .nav-item a {
    font-size: 15px
  }

  .button {
    padding: 5px
  }
}

@media screen and (min-width: 300px) and (max-width: 425px) {
  .navbar-brand img {
    width: 8em;
  }

  .navbar-nav {
    gap: 1.5em;
    padding: 20px 0px;
  }
}
</style>
