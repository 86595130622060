<template>
  <div class="select">
    <img loading="lazy" class="flag" :src="flags[app.language]" alt="">
    <div class="arrows cursor-pointer text-white" @click="openLanguages = !openLanguages">
      <ArrowDownSvg style="width: 20px;" v-show="!openLanguages"/>
      <ArrowUpSvg style="width: 20px;" v-show="openLanguages"/>
    </div>

    <div v-show="openLanguages" class="container-languages">
      <div
        class="language"
        v-for="language in languages"
        :key="language.code"
      >
        <img class="flag" :src="language.flag" alt="">
        <!-- <a href="#" @click="setLanguage(language.code)" class="m-0 font-montserrat-r">{{ $t(language.name) }}</a> -->
        <a :href="language.code !== 'en' ? `https://${language.code}.cloverleafaws.com` : 'https://cloverleafaws.com'" class="m-0 font-montserrat-r">{{ $t(language.name) }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import ArrowDownSvg from './Icons/ArrowDownSvg.vue'
import ArrowUpSvg from './Icons/ArrowUpSvg.vue'
import SpainFlag from '@/assets/icons/spain-flag-icon.svg'
import UsaFlag from '@/assets/icons/united-states-flag-icon.svg'
import BrazilFlag from '@/assets/icons/brazil-flag-icon.svg'
import { useAppStore } from '@/stores/app'
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    ArrowDownSvg,
    ArrowUpSvg
  },
  setup() {
    const i18n = useI18n();
    const app = useAppStore()

    const openLanguages = ref(false)
    const languages = [
      {
        name: 'spanish',
        code: 'es',
        flag: SpainFlag,
      },
      {
        name: 'english',
        code: 'en',
        flag: UsaFlag,
      },
      {
        name: 'portuguese',
        code: 'pt',
        flag: BrazilFlag,
      },
    ]

    const flags = {
      es: SpainFlag,
      en: UsaFlag,
      pt: BrazilFlag,
    }

    const setLanguage = (code) => {
      app.setLanguage(code)
      openLanguages.value = false
      localStorage.setItem('language', code)
    }

    watch(() => app.language, (newVal) => {
      i18n.locale.value = newVal
    })

    return {
      openLanguages,
      languages,
      setLanguage,
      app,
      flags
    }
  },
})
</script>

<style scoped>
  .cursor-pointer {
    cursor: pointer;
  }
  .container-languages {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: var(--white);
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 2;
  }
  .language {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    cursor: pointer;
    transition: color 0.3s;
    font-size: 13px;
    border-radius: 3px;
  }
  .language a {
    color: var(--primary-color);
    text-decoration: none;
  }
  .language:hover {
    background-color: var(--primary-color);
  }

  .language:hover a {
    color: var(--white);
  }
  .flag {
    width: 22px;
    height: 22px;
  }
  .select {
    display: flex;
    align-items: center;
    gap: 3px;
    position: relative;
  }
</style>
