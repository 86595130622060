const domains = {
    'localhost': 'en',
    'www.cloverleafaws.com': 'en',
    'cloverleafaws.com': 'en',
    'www.es.cloverleafaws.com': 'es',
    'es.cloverleafaws.com': 'es',
    'www.pt.cloverleafaws.com': 'pt',
    'pt.cloverleafaws.com': 'pt',
}

export default domains