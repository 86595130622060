import axios from '../application/http';

class WebService {
	static fetchGlobalData() {
		return axios({
			url: 'global-data'
		})
	}

  static fetchEvents() {
    return axios({
      url: 'events'
    })
  }

  static fetchCloverleafCountries({ filter, page }) {
    return axios({
      url: 'find-us/cloverleaf-countries',
      params: {
        filter,
        page
      }
    })
  }

}

export default WebService
