<template>
  <div class="d-flex align-items-center text-white cursor-pointer">
    <router-link class="me-2" :to="href">{{ $t(nameMenu) }}</router-link>
    <ArrowDownSvg v-if="!open" @click="$emit('onOpen', href.replace('/', ''))" />
    <ArrowUpSvg v-else @click="$emit('onOpen', null)" />
  </div>
  <div class="submenu" v-if="open">
    <ul>
      <li v-for="(item, index) of itemsMenu" :key="index" @click="$emit('onOpen', null)">
        <router-link :to="item.path">{{ $t(item.name) }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ArrowDownSvg from './Icons/ArrowDownSvg.vue'
import ArrowUpSvg from './Icons/ArrowUpSvg.vue'
import { useRoute } from 'vue-router'
import { computed, watch } from 'vue'

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      required: true
    },
    nameMenu: {
      type: String,
      required: true
    },
    itemsMenu: {
      type: Array,
      required: true
    },
    href: {
      type: String,
      required: false
    }
  },
  components: {
    ArrowDownSvg,
    ArrowUpSvg
  },
  setup(props, { emit }) {
    const route = useRoute();
    const routeName = computed(() => route.name)

    watch(routeName, () => {
      emit('onOpen', null)
    })
  },
})
</script>

<style scoped>
.submenu {
  position: absolute;
  background-color: var(--primary-color);
  border-radius: 0 0 0.5em 0.5em;
  top: 35px;
  padding-bottom: 1em;
  z-index: 2;
  min-width: 150px;
}

.submenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding-inline: 2em;
}
</style>
