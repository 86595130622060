<template>
  <span class="loader"></span>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '55px'
    }
  }
}
</script>

<style scoped>
.loader {
  width: v-bind(size);
  height: v-bind(size);
  border-radius: 50%;
  display: inline-block;
  border-top: 5px solid var(--tertiary-color);
  border-right: 5px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: v-bind(size);
  height: v-bind(size);
  border-radius: 50%;
  border-left: 5px solid var(--primary-color);
  border-bottom: 5px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
