import { defineStore } from 'pinia'

import WebService from '@/services/web.service'

export const useAppStore = defineStore({
  id: 'app',
  state: () => ({
    pdfUrl: '',
    prop12PdfSpanish: '',
    prop12PdfEnglish: '',
    loadingInit: true,
    language: 'en',
    banners: [],
    webInfo: null,
    events: [],
    testimonials: [],
    team: [],
    volunteers: [],
    sponsors: [],
    plans: [],
    videos: {},
  }),

  actions: {
    setLanguage(language) {
      this.language = language
    },
    setLoadingInit(payload) {
      this.loadingInit = payload
    },
    async fetchGlobalData() {
      const { data: res } = await WebService.fetchGlobalData()
      if (res && res.data) {
        const { videos, pdfUrl, prop12PdfSpanish, prop12PdfEnglish, banners, webInfo, testimonials, team, volunteers, sponsors, plans } = res.data

        this.videos = videos
        this.banners = banners
        this.webInfo = webInfo
        this.testimonials = testimonials
        this.team = team
        this.volunteers = volunteers
        this.sponsors = sponsors
        this.plans = plans
        this.pdfUrl = pdfUrl
        this.prop12PdfEnglish = prop12PdfEnglish
        this.prop12PdfSpanish = prop12PdfSpanish
      }
    },
    async fetchEvents() {
      const { data: res } = await WebService.fetchEvents()
      if (res && res.data) this.events = res.data
    },
    async fetchCloverleafCountries({ filter, page }) {
      const { data: res } = await WebService.fetchCloverleafCountries({ filter, page })
      return res.data
    }
  },
})
