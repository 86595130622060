<template>
  <p class="footer-title-section font-montserrat-sb text-center m-0">{{ $t('footer.contact') }}</p>
  <div class="contact-item">
    <img width="40" height="40" loading="lazy" src="@/assets/icons/sms.svg" alt="">
    <p class="m-0">{{ webInfo?.phone?.value }}</p>
  </div>
  <div class="contact-item">
    <img width="40" height="40" loading="lazy" src="@/assets/icons/mail.svg" alt="">
    <p class="m-0">{{ webInfo?.email?.value }}</p>
  </div>
  <div class="contact-item">
    <img width="40" height="40" loading="lazy" src="@/assets/icons/home.svg" alt="">
    <p class="direction m-0" v-html="webInfo?.address?.value"></p>
  </div>
</template>

<style scoped>
.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  font-size: 17px;
}

</style>

<script>
export default {
  props: {
    webInfo: {
      type: Object,
      default: null,
    },
  }
}
</script>
